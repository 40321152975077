import React, {useEffect, useState, Fragment} from "react";
import {priceFormatting} from "../utils";

export default function PriceLine(props) {

    const [price, setPrice] = useState(false);

    useEffect(() => {
        console.log('priceLine Use effect');
        fetch("/api/prices", {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        })
            .then((res) => res.json())
            .then((data) => onPriceLoaded(data));
    }, []);

    const onPriceLoaded = (data) => {
        setPrice(data);
    };

    if (!price) { return null; }

    let discountClass = 'blue-off-line-through';
    let freeTextClass = 'upgrade__free-test';

    if (props.white) {
        discountClass = 'grey-line-through';
        freeTextClass = 'upgrade__free-test upgrade__free-test--white';
    }

    return (
        <p className="upgrade__price">
            One-time fee of {priceFormatting((price.price - price.discount_amount), price.currency.symbol)}
            {price.discount_amount > 0 ? <Fragment>
                {' '}
                <span className={discountClass}>{priceFormatting((price.price), price.currency.symbol)}</span>
            </Fragment> : null}
            {' '}
            <span className={freeTextClass}> or <a href="/tests" aria-label="Free Test">Try for FREE!</a></span>
        </p>
    )

}